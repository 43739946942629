const binding = { modules: {}, dataActions: {} };

    const registerActionId = (actionPath) => {
        if (binding.dataActions[actionPath] &&
            binding.dataActions[actionPath].default &&
            binding.dataActions[actionPath].default.prototype &&
            binding.dataActions[actionPath].default.prototype.id) {
            binding.dataActions[binding.dataActions[actionPath].default.prototype.id] = binding.dataActions[actionPath];
        } else {
            Object.keys(binding.dataActions[actionPath] || {}).forEach(exportName => {
                if (binding.dataActions[actionPath][exportName] &&
                    binding.dataActions[actionPath][exportName].prototype &&
                    binding.dataActions[actionPath][exportName].prototype.Action) {
                    binding.dataActions[binding.dataActions[actionPath][exportName].prototype.id] = binding.dataActions[actionPath][exportName];
                }
            })
        }
    };

    const registerSanitizedActionPath = (sanitizedActionPath, dataAction) => {
        if (process.env.NODE_ENV === 'development') {
            if (!dataAction.default) {
                throw new Error('Data action path does not have a default export');
            }
            if (!(dataAction.default.prototype.id && binding.dataActions[dataAction.default.prototype.id]) || !binding.dataActions[sanitizedActionPath]) {
                binding.dataActions[sanitizedActionPath] = dataAction;
            }
        } else {
            binding.dataActions[sanitizedActionPath] = dataAction;
            if (!binding.dataActions[sanitizedActionPath].default) {
                throw new Error('Data action path ' + sanitizedActionPath + ' does not have a default export');
            }
            binding.dataActions[sanitizedActionPath].default.prototype.RegistrationId = sanitizedActionPath;
            if (binding.dataActions[sanitizedActionPath].default.prototype.id) {
                binding.dataActions[binding.dataActions[sanitizedActionPath].default.prototype.id] = sanitizedActionPath;
            }
        }
    };
    

    (binding.modules['cart'] = {
        c: () => require('@msdyn365-commerce-modules/cart/dist/lib/modules/cart/cart'),
        $type: 'containerModule',
        da: [{name:'cart',  path:'@msdyn365-commerce/global-state/dist/lib/data-actions/cart-state-data-action', runOn: 0},{name:'cartLinesPickUpOptions',  path:'@msdyn365-commerce-modules/retail-actions/dist/lib/get-pickup-options-for-cartlines', runOn: 0},{name:'catalogs',  path:'@msdyn365-commerce-modules/retail-actions/dist/lib/get-catalogs', runOn: 0},{name:'channelDeliveryOptionConfig',  path:'@msdyn365-commerce-modules/retail-actions/dist/lib/get-channel-delivery-option-configuration', runOn: 0},{name:'customerBalances',  path:'@msdyn365-commerce-modules/retail-actions/dist/lib/get-customer-balance', runOn: 0},{name:'customerInformation',  path:'@msdyn365-commerce-modules/retail-actions/dist/lib/get-customer', runOn: 0},{name:'deliveryOptions',  path:'@msdyn365-commerce-modules/retail-actions/dist/lib/get-delivery-options-for-cartlines', runOn: 0},{name:'featureState',  path:'@msdyn365-commerce-modules/retail-actions/dist/lib/get-feature-state', runOn: 0},{name:'orderTemplates',  path:'@msdyn365-commerce-modules/retail-actions/dist/lib/order-templates/get-order-templates-preview', runOn: 1},{name:'orgUnitLocations',  path:'@msdyn365-commerce-modules/retail-actions/dist/lib/search-org-unit-locations', runOn: 0},{name:'productAvailabilites',  path:'@msdyn365-commerce-modules/retail-actions/dist/lib/get-availabilities-cartlines', runOn: 0},{name:'products',  path:'@msdyn365-commerce-modules/retail-actions/dist/lib/get-products-in-active-cart', runOn: 0},{name:'storeSelectorStateManager',  path:'@msdyn365-commerce-modules/bopis-utilities/dist/lib/store-selector-state-manager/store-selector-state-manager-data-action', runOn: 1},{name:'wishlistItems',  path:'@msdyn365-commerce-modules/retail-actions/dist/lib/get-items-in-wishlist', runOn: 1},{name:'wishlists',  path:'@msdyn365-commerce-modules/retail-actions/dist/lib/get-wishlist-by-customer-id', runOn: 1}],
        
        iNM: true,
        ns: '@msdyn365-commerce-modules',
        n: 'cart',
        p: 'cart',
        
        pdp: '',
        
        
        md: 'node_modules/@msdyn365-commerce-modules/cart/dist/lib/modules/cart'
    });
        

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/bopis-utilities/dist/lib/store-selector-state-manager/store-selector-state-manager-data-action';
            let dataAction = require('@msdyn365-commerce-modules/bopis-utilities/dist/lib/store-selector-state-manager/store-selector-state-manager-data-action');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/retail-actions/dist/lib/get-availabilities-cartlines';
            let dataAction = require('@msdyn365-commerce-modules/retail-actions/dist/lib/get-availabilities-cartlines');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/retail-actions/dist/lib/get-catalogs';
            let dataAction = require('@msdyn365-commerce-modules/retail-actions/dist/lib/get-catalogs');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/retail-actions/dist/lib/get-channel-delivery-option-configuration';
            let dataAction = require('@msdyn365-commerce-modules/retail-actions/dist/lib/get-channel-delivery-option-configuration');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/retail-actions/dist/lib/get-customer';
            let dataAction = require('@msdyn365-commerce-modules/retail-actions/dist/lib/get-customer');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/retail-actions/dist/lib/get-customer-balance';
            let dataAction = require('@msdyn365-commerce-modules/retail-actions/dist/lib/get-customer-balance');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/retail-actions/dist/lib/get-delivery-options-for-cartlines';
            let dataAction = require('@msdyn365-commerce-modules/retail-actions/dist/lib/get-delivery-options-for-cartlines');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/retail-actions/dist/lib/get-feature-state';
            let dataAction = require('@msdyn365-commerce-modules/retail-actions/dist/lib/get-feature-state');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/retail-actions/dist/lib/get-items-in-wishlist';
            let dataAction = require('@msdyn365-commerce-modules/retail-actions/dist/lib/get-items-in-wishlist');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/retail-actions/dist/lib/get-pickup-options-for-cartlines';
            let dataAction = require('@msdyn365-commerce-modules/retail-actions/dist/lib/get-pickup-options-for-cartlines');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/retail-actions/dist/lib/get-products-in-active-cart';
            let dataAction = require('@msdyn365-commerce-modules/retail-actions/dist/lib/get-products-in-active-cart');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/retail-actions/dist/lib/get-wishlist-by-customer-id';
            let dataAction = require('@msdyn365-commerce-modules/retail-actions/dist/lib/get-wishlist-by-customer-id');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/retail-actions/dist/lib/order-templates/get-order-templates-preview';
            let dataAction = require('@msdyn365-commerce-modules/retail-actions/dist/lib/order-templates/get-order-templates-preview');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = '@msdyn365-commerce-modules/retail-actions/dist/lib/search-org-unit-locations';
            let dataAction = require('@msdyn365-commerce-modules/retail-actions/dist/lib/search-org-unit-locations');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = '@msdyn365-commerce/global-state/dist/lib/data-actions/cart-state-data-action';
            let dataAction = require('@msdyn365-commerce/global-state/dist/lib/data-actions/cart-state-data-action');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };
                        export const viewDictionary = {};
                        viewDictionary['__local__|__local__|themes|base|views|cart'] = {
                c: () => require('partner/themes/base/views/cart.view.tsx'),
                cn: '__local__-__local__-cart'
            };
viewDictionary['@msdyn365-commerce-modules|cart|modules|cart|cart'] = {
                c: () => require('@msdyn365-commerce-modules/cart/dist/lib/modules/cart/cart.view.js'),
                cn: '@msdyn365-commerce-modules-cart-cart'
            };
viewDictionary['@msdyn365-commerce-modules|fabrikam-design-kit|modules|fabrikam|views|cart'] = {
                c: () => require('@msdyn365-commerce-modules/fabrikam-design-kit/dist/lib/modules/fabrikam/views/cart.view.js'),
                cn: '@msdyn365-commerce-modules-fabrikam-design-kit-cart'
            };
viewDictionary['@msdyn365-commerce-theme|adventureworks-theme-kit|modules|adventureworks|views|cart'] = {
                c: () => require('@msdyn365-commerce-theme/adventureworks-theme-kit/dist/lib/modules/adventureworks/views/cart.view.js'),
                cn: '@msdyn365-commerce-theme-adventureworks-theme-kit-cart'
            };
window.__bindings__ = window.__bindings__ || {};
window.__bindings__.viewDictionary = {
                        ...window.__bindings__.viewDictionary || {},
                        ...viewDictionary
                    };